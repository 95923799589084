import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { StaticImage } from 'gatsby-plugin-image';
import Select from 'react-select';
import Countdown from 'react-countdown';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination } from 'swiper';
import Layout from '~/templates/Layout';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';
import { Bluetooth, Dollar, Phone, Push, Receiver, Rechargeable, ThinCross, Headset } from '~/components/elements/Icon';
import AddToCartButton from '~/components/shop/cart/AddToCartButton';
import Image from '~/components/elements/Image';
import { MediaImage } from '~/components/elements/Image';
import { LinkInner, UnderlineLink } from '~/components/elements/Link';
import { Newsletter } from '~/components/flexible/Newsletter';
import { ResourceCard } from '../components/landing/Resources';
import useBlogPosts from '~/hooks/useBlogPosts';
import { Button, MaybeLink } from '../components/elements/Link';

import 'swiper/css';
import 'swiper/css/pagination';

export default function productPage({ data: { wpPage, wp, wpProduct } }: { data: { wpProduct } }) {
  const { showHeaderAddToCart, showFooterAddToCart, scrollToCalculator } = wpProduct.postTypeProduct;

  return (
    <Layout wp={wp} headerColor="light-blue">
      <Seo post={wpProduct} title={wpProduct.name} />
      <Masthead
        {...wpProduct}
        HeadingTag="h1"
        showQuestionCta={true}
        showPromoBar={true}
        showAddToCart={showHeaderAddToCart}
        scrollToCalculator={scrollToCalculator}
      />
      <LayoutRenderer
        prefix="Product_Posttypeproduct_Sections_Section_Components_"
        sections={wpProduct?.postTypeProduct?.sections}
      />
      {showFooterAddToCart && (
        <Masthead {...wpProduct} HeadingTag="h2" showPromoBar={false} showQuestionCta={false} showAddToCart={true} />
      )}
    </Layout>
  );
}

export const Masthead = (props) => {
  const [variation, setVariation] = useState(null);
  let variationsCache;
  const {
    HeadingTag,
    showAddToCart,
    showPromoBar,
    showQuestionCta,
    scrollToCalculator,
    featuredImage,
    name,
    postTypeProduct,
    regularPrice,
    salePrice,
    galleryImages,
    image,
    shortDescription,
    attributes,
  } = props;

  const showPrice = postTypeProduct.showPrice;
  const showFromPrice = postTypeProduct.showFromPrice;

  try {
    variationsCache = JSON.parse(postTypeProduct.variationsCache);
    if (variationsCache.length === 0) {
      variationsCache = null;
    }
  } catch (e) {}

  function getThePrice(regularPrice) {
    let singlePrice = regularPrice?.split('- ')[0];
    let pairPrice = regularPrice?.split('- ')[1];

    if (pairPrice && pairPrice !== null) {
      return (
        <div className="flex w-full space-x-2 mt-6">
          <div>
            <div className="leading-[0.5]">{singlePrice}</div>
            <span className="italic t-15 text-black font-normal leading-[1]">Single</span>
          </div>
          <div className="leading-[0.4]">-</div>
          <div>
            <div className="leading-[0.5]">{pairPrice}</div>
            <span className="italic t-15 text-black font-normal leading-[1]">Pair</span>
          </div>
        </div>
      );
    } else {
      return <div>{singlePrice}</div>;
    }
  }

  return (
    <div>
      {showPromoBar && postTypeProduct?.specialOffer && <PromoBar {...postTypeProduct?.specialOfferContent} />}
      <section className="section !py-12 section-bot bg-blue-light" itemType="https://schema.org/Product" itemScope="">
        <div className="container">
          <div className="flex flex-col space-y-10 lg:space-y-0 lg:flex-row lg:space-x-10">
            <div className="w-full lg:flex-1">
              <ProductSlider images={galleryImages.nodes.length ? galleryImages.nodes : [image]} />
            </div>
            <div className="w-full lg:w-[45%] ">
              <div className="bg-white rounded-[10px] card-shadow p-5 py-10 lg:p-10 space-y-6">
                <div className="space-y-3">
                  <div className="space-y-1">
                    {postTypeProduct?.productVendor && (
                      <div className="text-blue font-semibold">{postTypeProduct.productVendor}</div>
                    )}
                    <HeadingTag className="t-32" itemProp="name">
                      {name}
                    </HeadingTag>
                  </div>
                  <div
                    className="trustpilot-widget w-[189px] xl:w-[420px] xl:-ml-2.5"
                    data-locale="en-US"
                    data-template-id="5419b6ffb0d04a076446a9af"
                    data-businessunit-id="5fc67a080737a300019b2dd4"
                    data-style-height="20px"
                    data-style-width="100%"
                    data-theme="light"
                    data-text-color="#0B3F6F"
                  >
                    <a href="https://www.trustpilot.com/review/yeshearing.com" target="_blank" rel="noopener">
                      <span className="hidden">Trustpilot</span>
                    </a>
                  </div>
                  {(postTypeProduct?.productDescription || shortDescription) && (
                    <div
                      className="t-18 t-black-65 space-y-3"
                      itemProp="description"
                      dangerouslySetInnerHTML={{
                        __html: postTypeProduct?.productDescription
                          ? postTypeProduct.productDescription
                          : shortDescription,
                      }}
                    />
                  )}

                  {postTypeProduct?.features && <Features {...postTypeProduct} />}
                  {postTypeProduct?.showPrice && (
                    <div className="t-24 text-blue" itemProp="offers" itemType="https://schema.org/Offer" itemScope="">
                      <meta itemProp="priceCurrency" content="USD" />
                      {variationsCache ? (
                        <meta itemProp="price" content={regularPrice?.split('- ')[0].replace('$', '')} />
                      ) : (
                        <meta itemProp="price" content={regularPrice} />
                      )}

                      {getThePrice(regularPrice)}
                    </div>
                  )}
                </div>
                {variationsCache && showAddToCart && (
                  <div className="space-y-4">
                    <VariationsSelect
                      variations={variationsCache}
                      setVariation={setVariation}
                      attributes={attributes}
                    />
                  </div>
                )}
                <div className="space-y-4">
                  <div className="w-full fw-button">
                    {showAddToCart ? (
                      <AddToCartButton product={props} variationId={variation} />
                    ) : (
                      <MaybeLink link={{ url: '/book-a-consultation/', title: 'Speak to a specialist' }}>
                        <Button
                          link={{ url: '/book-a-consultation/', title: 'Speak to a specialist' }}
                          buttonStyle="tertiary"
                          size="header"
                        />
                      </MaybeLink>
                    )}
                  </div>
                  <a
                    href="tel:877-220-6389"
                    className="block group button button-primary w-full mktst_ph_qhzhaVIYmmKwpq2beA8w"
                    target=""
                  >
                    877-220-6389
                  </a>

                  {showAddToCart && scrollToCalculator && (
                    <div className="flex justify-center space-x-4">
                      <a
                        href={`${scrollToCalculator ? '#twoColumnsCalculator' : 'tel:877-220-6389'}`}
                        className="flex items-center space-x-2 mktst_ph_qhzhaVIYmmKwpq2beA8w"
                      >
                        <div className="text-green">
                          <Dollar />
                        </div>
                        <div className="underline block text-navy font-medium">Spread the cost</div>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showQuestionCta && (
        <div className="bg-navy text-white">
          <div className="container container-full">
            <div className="px-5 lg:px-10 py-10 lg:py-4 flex flex-col space-y-5 lg:space-y-0 lg:flex-row items-center">
              <div className="t-21">{name}</div>
              <div className="lg:ml-auto flex flex-col space-y-5 lg:space-y-0 lg:flex-row items-center lg:space-x-10">
                <div className="flex items-center space-x-2 font-medium">
                  <div className="text-green">
                    <Headset />
                  </div>
                  <div>Questions? </div>
                  <a href="/book-a-consultation" className="underline block font-semibold">
                    Speak to a specialist
                  </a>
                </div>
                <a
                  href="tel:877-220-6389"
                  className="block group button button-secondary mktst_ph_qhzhaVIYmmKwpq2beA8w"
                  target=""
                >
                  877-220-6389
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ProductSlider = (props) => {
  const { images } = props;

  return (
    // <pre>{JSON.stringify(images, null, 4)}</pre>

    <Swiper
      modules={[A11y, Pagination]}
      className={`w-full z-10 relative !pb-12`}
      slidesPerView={'auto'}
      loop
      pagination={{ clickable: true }}
    >
      <div className="hidden pb-12" />
      {images?.map((image, i) => (
        <SwiperSlide key={`productSlide${i}`}>
          <div className="aspect-w-3 aspect-h-2 overflow-hidden">
            <Image
              itemProp="image"
              image={image}
              className="!absolute max-h-[400px]"
              objectFit="contain"
              objectPosition="center"
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export const Features = ({ features }) => {
  return (
    <ul className="grid sm:grid-cols-2 gap-2.5">
      {features?.map((feature, i) => (
        <li key={i} className="flex items-center space-x-4">
          <div className="w-4 h-4 fwh-svg">
            {feature?.feature?.value === 'rechargeable' ? (
              <Rechargeable />
            ) : feature?.feature?.value === 'push' ? (
              <Push />
            ) : feature?.feature?.value === 'bluetooth' ? (
              <Bluetooth />
            ) : (
              feature?.feature?.value === 'receiver' && <Receiver />
            )}
          </div>
          <div className="font-medium">{feature?.label}</div>
        </li>
      ))}
    </ul>
  );
};

export const PromoBar = (props) => {
  const [hidePromoBanner, setHidePromoBanner] = useState(false);

  const { heading, content, date } = props;

  return (
    <div className={`bg-orange text-white py-4 ${hidePromoBanner && 'hidden'}`}>
      <div className="container">
        <div className="flex space-y-5 lg:space-y-0 flex-col lg:flex-row lg:space-x-8 text-center lg:text-left items-center lg:justify-center relative">
          <div className="w-10 md:w-16">
            <StaticImage src="../assets/images/offer.png" alt="" className="w-full object-contain" />
          </div>
          <div className="space-y-1">
            {heading && <div className="font-semibold">{heading}</div>}
            {content && <div> {content} </div>}
          </div>
          <div className="leading-none md:leading-6">
            <div className="t-36 !font-body font-semibold">
              <Countdown date={date} />
            </div>
          </div>
          <button
            onClick={() => setHidePromoBanner(true)}
            className="absolute top-4 right-0 w-7 h-7 bg-black bg-opacity-30 text-white rounded-full overflow-hidden flex items-center justify-center hover:bg-opacity-100 transition-colors duration-200 ease-in-out"
          >
            <div className="w-2.5 fw-svg">
              <ThinCross />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const VariationsSelect = ({ variations, setVariation, attributes }) => {
  const [selectedAttributes, setSelectedAttributes] = useState({});
  let showTotalPrice = false;

  const removeAttributes = attributes.nodes.filter(
    (attribute) =>
      attribute.name !== 'Feature' &&
      attribute.name !== 'Style' &&
      attribute.name !== 'Brand' &&
      attribute.name !== 'Level' &&
      attribute.name !== 'Battery Options'
  );

  let products = [];
  variations.forEach((element) => {
    products.push(element.attributes);
  });

  function isDisabled(attribute, i) {
    if (i === 0) {
      showTheTotalPrice();
      return false;
    } else {
      showTheTotalPrice();
      return !selectedAttributes.hasOwnProperty(attribute);
    }
  }

  function showTheTotalPrice() {
    if (removeAttributes.length === Object.keys(selectedAttributes).length) {
      showTotalPrice = true;
    }
  }

  return (
    <div className="capitalize space-y-4">
      {removeAttributes.map((attribute, i) => {
        let previousAttribute = removeAttributes[i - 1];
        let options = [];
        attribute.options.forEach((element) => {
          options.push({ label: element.replace('-', ' '), value: element });
        });
        return (
          <label key={attribute.id} className="flex">
            <span className="w-28 pt-2 t-14">{attribute.name}*</span>
            <Select
              options={options}
              className="flex-1"
              onChange={(e) => {
                setSelectedAttributes({ ...selectedAttributes, [attribute.name]: e.value });
              }}
              isDisabled={isDisabled(previousAttribute?.name, i)}
            />
          </label>
        );
      })}
      <div className="h-16">
        {showTotalPrice && (
          <GetTotalPrice variations={variations} setVariation={setVariation} selectedAttributes={selectedAttributes} />
        )}
      </div>
    </div>
  );
};

export const GetTotalPrice = ({ variations, setVariation, selectedAttributes }) => {
  let finalResult = [];
  let finalPrice, isInStock;

  useEffect(() => {
    if (finalResult[0]?.variation_id) {
      setVariation(finalResult[0]?.variation_id);
    }
  }, [finalResult]);

  finalResult = searchAttributesArray(variations, selectedAttributes);

  finalPrice = finalResult[0]?.display_price;
  isInStock = finalResult[0]?.is_in_stock;

  console.log(finalResult);

  return (
    <>
      {isInStock ? (
        <div>
          <div className="pt-2 t-17 text-[#666]">Total</div>
          <div className="t-24 text-blue">${finalPrice}</div>
        </div>
      ) : (
        <div className="pt-6 t-24">
          <h3 className="text-[#666]">Out of Stock</h3>
        </div>
      )}
    </>
  );
};

function searchAttributesArray(variations, searchItems) {
  let searchItemsArray = { ...searchItems };
  let filterArray = [];
  let searchArray = [];

  /*if (searchItemsArray['Select Ear'] !== undefined) {
    //If variations contain Single/Pair
    delete searchItemsArray['Select Ear'];
    variations.forEach((element) => {
      if (Object.values(element.attributes).includes(searchItems['Select Ear'])) {
        filterArray.push(element);
      }
    });
    if (filterArray.length > 1) {
      filterArray.forEach((element) => {
        let res = Object.values(searchItemsArray).filter((item) => Object.values(element.attributes).includes(item));
        if (res.length != 0) {
          searchArray.push(element);
        }
      });
    } else {
      searchArray = filterArray;
    }
  } else {
    //If not match all attributes
  variations.forEach((element) => {
    let res = Object.values(searchItemsArray).every((item) => Object.values(element.attributes).includes(item));
    if (res) searchArray.push(element);
  });
  }*/

  variations.forEach((element) => {
    let res = Object.values(searchItemsArray).every((item) => Object.values(element.attributes).includes(item));
    if (res) searchArray.push(element);
  });

  return searchArray;
}

export const pageQuery = graphql`
  query Product($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
    wpProduct(id: { eq: $id }) {
      ...GeneratedWpProduct
      postTypeProduct {
        allFeatures {
          singleFeature
        }
        productVendor
      }
      ... on WpSimpleProduct {
        id
        name
        regularPrice
        salePrice
      }
      ... on WpVariableProduct {
        id
        name
        regularPrice
        salePrice
        attributes {
          nodes {
            name
            id
            variation
            visible
          }
        }
      }
    }
  }
`;
