import React, { useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { AppContext } from '~/components/context/AppContext';
import { getFormattedCart } from '~/utils/functions';
import Link from 'gatsby-link';
import { v4 } from 'uuid';
import GET_CART from '~/queries/get-cart';
import ADD_TO_CART from '~/mutations/add-to-cart';

const AddToCartButton = (props) => {
  const { product, variationId } = props;

  const productQtyInput = {
    clientMutationId: v4(), // Generate a unique id.
    productId: variationId !== null ? variationId : product?.databaseId,
  };

  /* eslint-disable */
  const [cart, setCart] = useContext(AppContext);
  const [showViewCart, setShowViewCart] = useState(false);
  const [requestError, setRequestError] = useState(null);

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // console.warn( 'completed GET_CART' );

      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);

      localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));

      // Update cart data in React Context.
      setCart(updatedCart);
    },
  });

  // Add to Cart Mutation.
  const [addToCart, { data: addToCartRes, loading: addToCartLoading, error: addToCartError }] = useMutation(
    ADD_TO_CART,
    {
      variables: {
        input: productQtyInput,
      },
      onCompleted: () => {
        // If error.
        if (addToCartError) {
          setRequestError(addToCartError?.graphQLErrors[0]?.message);
        } else {
          setRequestError('');
        }

        // On Success:
        // 1. Make the GET_CART query to update the cart with new values in React context.
        refetch();

        // 2. Show View Cart Button
        setShowViewCart(true);
        window.location.replace('/cart/');
      },
      onError: (error) => {
        console.log(error);
        if (error) {
          setRequestError(error?.graphQLErrors[0]?.message);
        }
      },
    }
  );

  const handleAddToCartClick = () => {
    setRequestError(null);
    addToCart();
  };

  return (
    <div className="w-full">
      {/*	Check if its an external product then put its external buy link */}
      {'ExternalProduct' === product.nodeType ? (
        <a href={product.externalUrl} target="_blank">
          <button className="button button-primary">Buy Now</button>
        </a>
      ) : (
        <>
          {requestError?.length > 0 && (
            <div
              className="p-4 mt-4 border text-error border-error rounded-[4px] mb-4"
              dangerouslySetInnerHTML={{ __html: requestError }}
            />
          )}
          <button onClick={handleAddToCartClick} className="w-full button button-tertiary">
            {addToCartLoading ? (
              <>
                <span>Adding to cart...</span>
                <span aria-hidden>Adding to cart...</span>
              </>
            ) : (
              <>
                <span>Add to cart</span>
                <span aria-hidden>Add to cart</span>
              </>
            )}
          </button>
        </>
      )}
    </div>
  );
};

export default AddToCartButton;
